<template>
  <div>
    <page-list
      title="Questions"
      to-label="Add"
      :headers="headers"
      :items="items"
      :paging="paging"
      :raiseAddEvent="true"
      :useFilters="false"
      :showAddButton="showAddButton"
      @search="onSearch"
      @clear="onSearchClear"
      @page:changed="onPageChange"
      @row:view="view"
      @add="onShowAddQuestion"
      @sortBy:changed="onSortByChanged"
      @sortDesc:changed="onSortDescChanged"
    >
      <template v-slot:filters> </template>

      <template v-slot:item.relations="{ item }">
        <div class="text-right">
            <v-avatar 
              v-for="relation in item.relationsMap"
              :key="relation.entityType" 
              color="primary" 
              size="32" 
              class="mr-1"
            >
              <span class="white--text text-capitalize">{{
                relation.entityType[0]
              }}</span>
            </v-avatar>
        </div>
      </template>
       <!-- <template v-slot:item.relations="{ item }">
        <div class="text-right">
          <v-badge
            v-for="relation in item.relationsMap"
            :key="relation.entityType"
            :content="relation.count"
            :value="relation.count"
            class="mr-2"
            color="grey"
            overlap
          >
            <v-avatar color="primary" size="32">
              <span class="white--text text-capitalize">{{
                relation.entityType[0]
              }}</span>
            </v-avatar>
          </v-badge>
        </div>
      </template> -->

      <template v-slot:item.publishDateTime="{ item }">
        {{ item.publishDateTime | dateFormat }}
      </template>
    </page-list>

    <question-edit-modal
      v-if="viewStyle === 'dialog'"
      v-model="showQuestionDialog"
      :mediaOptions="mediaOptions"
      :question="selectedQuestion"
      :title="dialogTitle"
      @delete="onQuestionDelete"
      @save="onQuestionSave"
      @cancel="onQuestionCancel"
    />

  </div>
</template>

<script>
import _ from 'lodash'
import { questions as questionsApi } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'
import QuestionEditModal from '@/components/knowledgeCheck/questions/edit.modal.vue'

export default {
  components: {
    pageList,
    QuestionEditModal
  },
  props: {
    moduleId: { type: String, default: null },
    componentId: { type: String, default: null },
    topicId: { type: String, default: null },
    procedureId: { type: String, default: null },
    showRelations: { type: Boolean, default: false },
    mediaOptions: { type: Object, default: null },
    showAddButton: { type: Boolean, default: true },
    viewStyle: { type: String, default: 'dialog'}
  },
  data() {
    return {
      dialogTitle: 'Add Question',
      loaded: false,
      model: {},
      items: [],
      paging: {},
      selectedQuestion: {},
      showQuestionDialog: false
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: 'Question', value: 'text' },
        { text: 'Status', value: 'status', width: '10%' }
      ]
      if (this.showRelations) {
        headers.push({ text: 'Relations', value: 'relations', width: '10%' })
      }
      return headers
    },
    showAdd() {
      return (
        this.moduleId || this.componentId || this.topicId || this.procedureId
      )
    },
    defaultRelation() {
      const relation = {
        entityId: null,
        entityType: null
      }

      if (this.procedureId) {
        relation.entityId = this.procedureId
        relation.entityType = 'procedure'
      } else if (this.topicId) {
        relation.entityId = this.topicId
        relation.entityType = 'topic'
      } else if (this.componentId) {
        relation.entityId = this.componentId
        relation.entityType = 'component'
      } else if (this.moduleId) {
        relation.entityId = this.moduleId
        relation.entityType = 'module'
      }

      return relation
    }
  },
  watch: {
    moduleId(val) {
      this.model.moduleId = val
    },
    componentId(val) {
      this.model.componentId = val
    },
    topicId(val) {
      this.model.topicId = val
    },
    procedureId(val) {
      this.model.procedureId = val
    }
  },
  mounted() {
    this.model = {
      moduleId: this.moduleId,
      componentId: this.componentId,
      topicId: this.topicId,
      procedureId: this.procedureId
    }

    this.loadQuestions(this.model)
  },
  methods: {
    async loadQuestions(model) {
      const response = await questionsApi.search(model)

      this.items = response.data.results
      this.paging = response.data.meta.paging

      for (const i in this.items) {
        const item = this.items[i]
        item.relationsMap = _.chain(item.relations)
          .groupBy('entityType')
          .map((items, entityType) => ({ entityType, count: items.length }))
          .value()
      }

      this.loaded = true
    },
    onShowAddQuestion() {
      this.selectedQuestion = {
        relations: [this.defaultRelation]
      }
      this.showQuestionDialog = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.model.page = pagination.page
        this.loadQuestions(this.model)
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.model = { ...this.model, term, page: 1 }
      this.paging.currentPage = 1
      this.loadQuestions(this.model)
    },
    onSortByChanged(sortBy) {
      this.model = { ...this.model, orderBy: sortBy }
      this.loadQuestions(this.model)
    },
    onSortDescChanged(sortDesc) {
      this.model = { ...this.model, isAscending: !sortDesc }
      this.loadQuestions(this.model)
    },
    async view(item) {
      this.dialogTitle = 'Edit Question'
      this.selectedQuestion = await questionsApi.fetch(item.id)
      this.showQuestionDialog = true
    },
    async onQuestionDelete(model) {
      const deleted = this.items.find(
        item => item.id === model.id
      )
      this.items.splice(this.items.indexOf(deleted), 1)
      this.showQuestionDialog = false
      this.selectedQuestion = null
    },
    async onQuestionSave() {
      await this.loadQuestions(this.model)
      this.showQuestionDialog = false
    },
    onQuestionCancel() {
      this.showQuestionDialog = false
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
