<template>
  <div class="text-center">
    <v-dialog v-model="show" class="modal" persistent width="500">
      <v-card>
        <v-card-title class="headline" style="text-transform: capitalize">
          Delete {{ unit }}
        </v-card-title>

        <v-card-text>
          <p>
            Are you sure you want to permanantly delete the {{ unit }}:
            <b> {{ name }}</b
            >?
          </p>
          <p>This content will be gone forever.</p>

          <slot name="text" />

          <p>
            Please type <b>"{{ name }}"</b> to confirm.
          </p>
          <v-text-field
            label="Component Name"
            required
            :rules="nameRules"
            v-model="confirmName"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" plain @click="cancel"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" tile large @click="onDelete" :disabled="!valid">
            Yes, Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    unit: { type: String },
    name: { type: String },
    value: Boolean
  },
  data() {
    return {
      confirmName: null,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v === this.name) || 'Names must match'
      ]
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    valid() {
      return this.name && this.name === this.confirmName
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('onDelete')
    }
  }
}
</script>
