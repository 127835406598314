import store from '@/store'
import moduleAccessHandler from './module-access-handler'
import organizationRoleHandler from './organization-role-handler'

const policies = {
  'content-create': () => {
    return organizationRoleHandler(['content-creator', 'content-publisher'])
  },
  'content-publish': () => {
    return organizationRoleHandler(['content-publisher'])
  },
  'org-groups': () => {
    return organizationRoleHandler(['sysadmin', 'orgadmin'])
  },
  'org-members': () => {
    return organizationRoleHandler(['sysadmin', 'orgadmin'])
  },
  'org-reports': () => {
    return true
  },
  'media-library': () => {
    return organizationRoleHandler(['sysadmin', 'orgadmin'])
  },
  'access-requests': () => {
    return organizationRoleHandler(['sysadmin'])
  },
  'module-update': (resource) => {
    return moduleAccessHandler(resource, 'write')
  },
  'module-publish': (resource) => {
    return moduleAccessHandler(resource, 'publish')
  },
  sysadmin: () => {
    return store.getters['user/isSysAdmin']
  }
}

const authorize = (policy, resource) => {
  if (policies[policy]) {
    return policies[policy](resource)
  }
  return false
}

export default {
  authorize,
  policies
}
