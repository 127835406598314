<template>
  <v-dialog v-model="show" min-width="450px">
    <v-app-bar flat elevation="0">
      <v-toolbar-title>{{ options.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <media-selector
        :accept="accept"
        v-model="showSelector"
        :options="selectorOptions"
        @selected="onItemSelected"
      />

      <v-btn icon @click="showSelector = !showSelector" class="mr-3">
        <v-icon>mdi-folder</v-icon>
      </v-btn>

      <media-upload
        v-if="!uploading"
        :accept="accept"
        @onUploaded="onFileUploaded"
        class="mr-3"
      />

      <v-btn tile icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card flat tile>
      <v-card-text v-if="options.entityId">
        <v-container>
          <v-row>
            <v-col
              v-for="m in media"
              :key="m.entityid"
              class="image"
              xs="6"
              sm="6"
              md="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                  @click="selectItem(m)"
                >
                  <v-container fluid>
                    <component
                      :is="`${m.mediaItem.mediaType}-preview`"
                      :value="m"
                      :editable="false"
                      :height="height"
                    />
                  </v-container>
                  <v-card-actions class="text-truncate">
                    {{ m.mediaItem.name }}
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
            <v-col v-if="showUpload" xs="6" sm="6" md="3">
              <v-container>
                <v-progress-circular v-if="uploading"></v-progress-circular>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import { mediaTypePropertyValidator, acceptMediaTypes } from '../utils'
import { media as mediaApi } from '@/services/api'
import mediaSelector from './media-selector.vue'
import mediaUpload from '@/components/media/upload/modal.vue'

const defaultOptions = {
  showUpload: true,
  title: 'Media Reference Selector',
  mediaType: 'image'
}

export default {
  components: {
    mediaSelector,
    mediaUpload
  },
  props: {
    mediaType: {
      type: String,
      validator: (prop) => mediaTypePropertyValidator(prop)
    },
    value: { type: Boolean, default: false },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loaded: false,
      media: [],
      paging: {
        currentPage: 1
      },
      show: false,
      showUpload: false,
      showSelector: false,
      uploading: false
    }
  },
  computed: {
    accept() {
      return acceptMediaTypes(this.mediaType)
    },
    height(){
      if(this.$vuetify.breakpoint.smAndDown){
          return '225px'
        }
        return '325px'
    },
    selectorOptions() {
      return _.defaults(
        { title: 'Media Browser', showUpload: false },
        this.options
      )
    }
  },
  watch: {
    options(val) {
      _.defaults(val, defaultOptions)
    },
    show(val) {
      this.$emit('input', val)
    },
    async value(val) {
      this.show = val
      if (val) {
        _.defaults(this.options, defaultOptions)
        this.loadReferences()
      }
    }
  },
  mounted() {
    if (this.entityId) {
      this.loadReferences()
    }
  },
  methods: {
    cancel() {
      this.show = false
    },
    selectItem(image) {
      this.$emit('selected', image)
    },
    async loadReferences() {
      const params = {
        mediaType: this.mediaType
      }

      const response = await mediaApi.references.get(this.options, {
        params: params
      })

      this.paging = response.data.meta.paging
      this.media = response.data.results
      this.loaded = true
    },
    async onFileUploaded(upload) {
      this.uploading = true

      console.log('media-reference-selector onFileUploaded', upload)

      const name = upload.file.name.split('.').shift()

      const model = {
        name,
        description: name,
        entityId: this.options.entityId,
        entityType: this.options.entityType,
        mediaType: this.mediaType
      }

      const response = await mediaApi.api.postFile(upload.file, model)
      const newItem = await mediaApi.references.fetch(
        response.id,
        response.referenceId
      )
      this.media.push(newItem)

      this.uploading = false
    },
    async onItemSelected(item) {
      console.log('file selected', item)
      this.uploading = true
      this.showSelector = false

      const response = await mediaApi.references.post(item.id, this.options)
      const newItem = await mediaApi.references.fetch(item.id, response.id)
      this.media.push(newItem)
      this.uploading = false
    }
  }
}
</script>
