<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                label="Module Name"
                v-model="model.moduleId"
                :items="modules"
                item-text="name"
                item-value="id"
                :disabled="!isNew"
                :rules="[(v) => !!v || 'name is required']"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="Permissions"
                label="Permissions"
                v-model="model.permissions"
                item-text="permissions"
                item-value="permissions"
                clearable
                style="text-transform: capitalize"
              ></v-select>
            </v-col>
          </v-row>
          <date-picker
            label="Expiration"
            v-model="model.expiresFormatted"
            value="expiresFormatted"
          ></date-picker>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="model.id" tile color="error" @click="deleteGroupModule">
          Delete
        </v-btn>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onSave"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <deleteDialog
      :name="model.moduleName"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancelDelete"
      @onDelete="onDelete"
    ></deleteDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { modules as modulesApi } from '@/services/api'
import datePicker from '@/components/datetime/date-picker.menu'
import { Permissions } from '@/enums/index.js'
import deleteDialog from '@/components/dialogs/delete'
import { format } from '@/services/datetime'

export default {
  components: { datePicker, deleteDialog },
  props: {
    value: { type: Object, required: true },
    label: { type: String, default: 'Create' }
  },
  data() {
    return {
      unit: 'group module',
      model: {},
      valid: true,
      modules: [],
      Permissions,
      date: null,
      deleteDialog: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ]
    }
  },
  computed: {
    isNew() {
      return this.model.id ? false : true
    }
  },
  async mounted() {
    this.model = _.cloneDeep(this.value)
    const response = await modulesApi.get()
    this.modules = response.data.results
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSave() {
      if (this.model.expiresFormatted) {
        this.model.expires = format.toISO(this.model.expiresFormatted)
      }
      this.$emit('save', this.model)
    },
    deleteGroupModule() {
      this.deleteDialog = true
    },
    onCancelDelete() {
      this.deleteDialog = false
    },
    onDelete() {
      this.deleteDialog = false
      this.$emit('delete')
    }
  }
}
</script>

<style></style>
