<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        plain
        v-policy:module-update="module"
        :to="{
          name: 'component-edit',
          params: { moduleId: moduleId, componentId: model.id }
        }"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="action in actions"
              :key="action.key"
              @click="selectedAction(action)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="action.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-policy:module-publish="module"></v-divider>
            <v-list-item
              v-policy:module-publish="module"
              v-if="model.status === 'draft'"
              @click="onPublish"
            >
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                (model.status === 'draft' && !model.isDeleted) ||
                (model.status === 'published' && !model.isDeleted)
              "
              v-policy:module-publish="module"
              @click="showArchive"
            >
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showRestore"
            >
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="module"
              @click="showDelete"
            >
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <version-chips v-model="model"></version-chips>

    <v-row class="mt-0">
      <v-col class="text-center">
        <cover-image v-model="model.coverImage" />
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col>
        <description
          class="mr-5"
          :description.sync="model.description"
        ></description>
      </v-col>
    </v-row>

    <topicsTable
      :v-if="model"
      :module="module"
      :topics="topics"
      @viewTopic="viewTopic"
      @addTopic="addTopic"
      class="margin"
    ></topicsTable>

    <proceduresTable
      :v-if="model"
      :module="module"
      :items="procedures"
      @viewProcedure="viewProcedure"
      @addProcedure="addProcedure"
      class="margin"
    ></proceduresTable>

    <versions-modal
      v-model="showVersions"
      :items="model.versions"
      @view="onViewVersion"
    />

    <archiveDialog
      :name="model.name"
      :unit="unit"
      v-model="archiveDialog"
      @cancel="onCancel"
      @archive="onArchive"
    >
      <p slot="text">
        All currently published data associated with this {{ unit }} will become
        unavailable.
      </p>
    </archiveDialog>

    <restoreDialog
      :name="model.name"
      :unit="unit"
      v-model="restoreDialog"
      @cancel="onCancel"
      @restore="onRestore"
    ></restoreDialog>

    <deleteDialog
      :name="model.name"
      :unit="unit"
      v-model="deleteDialog"
      @cancel="onCancel"
      @onDelete="onDelete"
    >
      <p slot="text">
        All topics and media items not shared with other {{ unit }}s will also
        be deleted
      </p>
    </deleteDialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { components as componentsApiBuilder } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import topicsTable from './topics-table'
import proceduresTable from './procedures-table'
import versionsModal from '@/components/publishing/versions-modal.vue'

export default {
  mixins: [contentPageMixin],
  components: {
    proceduresTable,
    topicsTable,
    versionsModal
  },
  data() {
    return {
      unit: 'component',
      componentsApi: null,
      model: {},
      actions: [
        {
          key: 'knowledge-check',
          title: 'Knowledge Check',
          routeName: 'component-knowledgecheck'
        },
        {
          key: 'versions',
          title: 'Versions',
          callback: () => (this.showVersions = true)
        }
        // {
        //   key: 'component-view',
        //   title: 'View',
        //   routeName: ''
        // }
      ],
      showVersions: false
    }
  },
  computed: {
    actionRouteParams() {
      return { componentId: this.component.id }
    },
    archiveEnabled() {
      return this.actions.map((action) => {
        action.disabled =
          this.model.status === 3 && action.key === 'component-archive'
        return action
      })
    },
    topics() {
      return this.model.topics ? this.model.topics : []
    },
    procedures() {
      return this.model.procedures ? this.model.procedures : []
    }
  },
  async mounted() {
    this.componentsApi = componentsApiBuilder.build(this.moduleId)

    if (this.component) {
      this.model = { ...this.component }
    }
  },
  watch: {
    component(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    async loadComponent(id) {
      this.model = await this.componentsApi.fetch(id)
    },
    onPublish() {
      this.$router.push({
        name: 'component-publish',
        params: {
          moduleId: this.moduleId,
          componentId: this.componentId
        }
      })
    },
    async onArchive() {
      await this.componentsApi.archive(this.componentId)
      this.model.status = 'archived'
      this.loadComponent(this.componentId)
      this.archiveDialog = false
      this.addMessage({
        message: `Component '${this.model.name}' has been archived.`
      })
    },
    async onRestore() {
      await this.componentsApi.restore(this.componentId)
      this.model.status = 'draft'
      this.loadComponent(this.componentId)
      this.restoreDialog = false
      this.addMessage({
        message: `Component '${this.model.name}' has been restored.`
      })
    },
    async onDelete() {
      await this.componentsApi.delete(this.componentId)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'component-index' })
      this.addMessage({
        message: `Component '${this.model.name}' has been deleted.`
      })
    },
    addTopic() {
      this.$router.push({
        name: 'topic-create',
        params: { moduleId: this.moduleId, componentId: this.componentId }
      })
    },
    viewTopic(item) {
      this.$router.push({
        name: 'topic-detail',
        params: {
          moduleId: this.moduleId,
          componentId: this.componentId,
          topicId: item.id
        }
      })
    },
    addProcedure() {
      this.$router.push({
        name: 'procedure-create',
        params: { moduleId: this.moduleId, componentId: this.componentId }
      })
    },
    viewProcedure(item) {
      this.$router.push({
        name: 'procedure-detail',
        params: {
          moduleId: this.moduleId,
          componentId: this.componentId,
          procedureId: item.id
        }
      })
    },
    onViewVersion() {
      this.showVersions = false
      //Todo - link to module version view
    }
  }
}
</script>

<style scoped>
.status {
  margin: 0;
  text-transform: capitalize;
}
.margin {
  margin: 5px 10px;
}
</style>
