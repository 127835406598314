<template>
  <div class="text-center">
    <v-dialog v-model="show" class="modal" persistent>
      <v-app-bar flat elevation="0">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card flat tile>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Step Title"
                  required
                  v-model="model.name"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="model.id">
              <v-col cols="12">
                <editor
                  v-model="model.content"
                  placeholder="add procedure content"
                  :options="options"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="model.id" plain color="error" @click="onDelete">
            Delete
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel"> Cancel </v-btn>
          <v-btn tile large color="primary" @click="save" :disabled="!valid">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import editor from '@/components/tiptap/editor'

export default {
  components: {
    editor
  },
  props: {
    step: { type: Object, required: true },
    show: { type: Boolean, default: false }
  },
  data() {
    return {
      model: {},
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ],
      valid: true
    }
  },
  computed: {
    ...mapGetters('context', ['procedure', 'module', 'component']),
    title() {
      return `${this.step.id ? 'Edit' : 'Add'} Step`
    },
    options() {
      return {
        entityId: this.model.id,
        entityType: 'procedurestep',
        moduleId: this.module.id,
        componentId: this.component.id, 
        hideProcedures: true
      }
    }
  },
  watch: {
    step(val) {
      this.model = _.cloneDeep(val) || {}
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.step) || {}
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete', this.model)
    },
    save() {
      this.$emit('save', this.model)
    }
  }
}
</script>
