<template>
  <div class="d-flex align-center" id="login">
    <v-toolbar-items v-if="isAuthenticated">
      <v-menu bottom left offset-y v-model="showMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on" id="login">
            <v-icon x-small>mdi-account</v-icon>
            <span class="pl-1 pr-1">{{ profile.name }} </span>
            <v-icon x-small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item link :href="profileLink" target="_blank" title="Profile">
            <v-list-item-icon>
              <v-icon small>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>User Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="canChangeOrganization"
            title="Change Organization"
            @click="changeOrganization"
          >
            <v-list-item-icon>
              <v-icon small>mdi-sitemap</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Change Organization</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="logout">
            <v-list-item-icon>
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click.stop="help" title="Help">
            <v-list-item-icon>
              <v-icon small>mdi-help-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Help</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar-items>
      <v-btn
        v-if="errors.length > 0"
        icon
        small
        :title="errorTooltip"
        @click.stop="viewErrors"
      >
        <v-icon small color="error lighten-2">mdi-alert-circle</v-icon>
        {{ errors.length }}
      </v-btn>
    </v-toolbar-items>

    <v-toolbar-items v-if="!isAuthenticated">
      <v-btn icon title="sign in" @click="login">
        <v-icon small>sign-in-alt</v-icon>
      </v-btn>
    </v-toolbar-items>
    <change-organization
      v-if="isAuthenticated"
      v-model="showChangeOrganization"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileMixin from '@/components/mixins/profile'
import AuthService from '@/services/auth.service.js'
import ChangeOrganization from '@/components/auth/change-organization'

export default {
  mixins: [profileMixin],
  components: {
    ChangeOrganization
  },
  data() {
    return {
      showMenu: false,
      showChangeOrganization: false,
      user: null
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    ...mapGetters('user', ['profile', 'isSysAdmin']),
    errorTooltip() {
      return `${this.errors.length} errors`
    },
    canChangeOrganization() {
      return this.isSysAdmin || this.profile.membership.length > 1
    }
  },
  mounted() {
    AuthService.getUser().then((user) => {
      if (user) {
        this.user = user
      }
    })
  },
  methods: {
    login() {
      AuthService.login()
    },
    logout() {
      AuthService.logout()
    },
    help() {
      this.$router.push({ name: 'help' })
    },
    show() {
      this.showMenu = true
    },
    changeOrganization() {
      this.showChangeOrganization = true
    },
    viewErrors() {
      let msg = 'ToDo: move this to top level view\n'
      for (let i = 0; i < this.errors.length; i++) {
        msg = `${msg} ${i + 1}: ${this.errors[i].message}\n`
      }
      alert(msg)
    }
  }
}
</script>

<style scoped>
#login {
  color: #475569;
}
</style>
