<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        v-policy:module-update="model"
        plain
        :to="{ name: 'media-edit', params: { mediaId: model.id } }"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item v-if="model" @click="showVersions = !showVersions">
              <v-list-item-content>
                <v-list-item-title>Version History</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <v-row class="mt-0 pb-4">
      <v-col class="md-9 media-col">
        <template v-if="mediaComponent">
          <component :is="mediaComponent" v-model="model" :no-cache="true" />
        </template>
      </v-col>
      <v-col class="md-3">
        <v-card flat>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Name</v-list-item-title>
              <v-list-item-subtitle>{{ model.name }} </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Description</v-list-item-title>
              <v-list-item-subtitle>{{
                model.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <versions-modal v-model="showVersions" :model="model" />
    <references-table class="margin" :items="references"></references-table>
  </div>
</template>

<script>
import { media } from '@/services/api'
import MediaImage from '@/components/media/media-image.vue'
import ContentPageMixin from '@/components/mixins/content-page.vue'
import VersionsModal from './versions/modal.vue'
import ReferencesTable from './references-table.vue'

export default {
  mixins: [ContentPageMixin],
  components: {
    MediaImage,
    ReferencesTable,
    VersionsModal
  },
  data() {
    return {
      model: {},
      references: [],
      actions: [],
      showVersions: false
    }
  },
  computed: {
    id() {
      return this.$route.params.mediaId
    },
    actionRouteParams() {
      return { mediaId: this.id }
    },
    crumbs() {
      const items = [{ text: 'Media', to: { name: 'media-index' } }]
      if (this.model) {
        items.push({ text: this.model.name })
      }

      return items
    },
    mediaComponent() {
      if (!this.model?.mediaType) {
        return null
      }

      return `${this.model.mediaType}-preview`
    }
  },
  watch: {
    id(newVal) {
      this.load(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.load(this.id)
    }
  },
  methods: {
    async load(id) {
      this.model = await media.api.fetch(id)
      this.model.mediaId = this.model.id

      this.references = await media.api.refs(id)
    }
  }
}
</script>
