<template>  
  <v-card tile flat v-if="model">
    <v-card-text style="max-height:80%">
      <v-form ref="form" v-model="valid">
        <v-textarea
          label="Question Text"
          auto-grow
          v-model="model.text"
        ></v-textarea>

        <v-row>
          <v-col cols="11">
            <v-card max-width="100%" flat v-if="model.mediaItem">
              <v-responsive>
                <media-image
                  v-model="model.mediaItem"
                  resolution="original"
                  class="cover-image text-center"
                  :background="false"
                  height="100%"
                ></media-image>
              </v-responsive>
            </v-card>
           
            <media-selector
              v-model="selectImage"
              :options="mediaOptions"
              mediaType="image"
              @selected="onImageSelected"
            />
          </v-col>
          <v-col class="text-right">
            <v-btn icon @click="selectImageFor(model)">
              <v-icon>mdi-camera</v-icon> 
            </v-btn>
            <v-btn icon v-if="model.mediaItem"  @click="removeImageFor(model)">
              <v-icon>mdi-camera-off</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        

        <h4 class="mt-2">Choices</h4>

        <v-select
          :items="questionTypes"
          label="Answer Type"
          item-text="text"
          item-value="id"
          v-model="model.questionType"
          style="max-width:250px"
          @change="onQuestionTypeChange"
        ></v-select>
        
        <div v-for="choice in model.choices">
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-if="model.questionType === 'checkbox'"
                v-model="choice.isAnswer"
                dense
                @change="onAnswerChange(choice)"
              ></v-checkbox>
              <v-radio-group
                v-if="model.questionType === 'radio'"
                dense
                v-model="choice.isAnswer"
                @change="onAnswerChange(choice)"
              >
                <v-radio
                  dense
                  :value="true"
                  @change="onAnswerChange(choice)"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="10">
              <v-textarea
                label="Choice Text"
                dense
                auto-grow
                rows="2"
                v-model="choice.label"
              ></v-textarea>
              
              <media-image
                  v-model="choice.mediaItem"
                  resolution="original"
                  class="cover-image text-center"
                  :background="false"
                ></media-image>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="text-right text-no-wrap">
              <v-btn icon @click="selectImageFor(choice)">
                <v-icon>mdi-camera</v-icon> 
              </v-btn>
              <v-btn v-if="choice.mediaItem" icon @click="removeImageFor(choice)">
                <v-icon>mdi-camera-off</v-icon>
              </v-btn>
              <v-btn icon @click="removeChoice(choice)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-btn @click="addChoice" text color="primary">
          <v-icon>mdi-plus</v-icon>
          Add Choice</v-btn
        >
      </v-form>
    </v-card-text>
   
    <v-card-actions>
      <v-btn plain color="red" @click="onDelete">Delete</v-btn>
      <v-spacer></v-spacer>
      <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
      <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
        Save
      </v-btn>
      <v-btn tile large color="primary" @click="onPublish" :disabled="!valid">
        Publish
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { nanoid } from 'nanoid'
import MediaSelector from '@/components/media/selectors/media-selector.vue'
import MediaImage from '@/components/media/media-image.vue'

export default {
  components: {
    MediaImage,
    MediaSelector
  },
  props: {
    value: { type: Object, required: true },
    mediaOptions: { type: Object, required: false }
  },
  data() {
    return {
      model: {
        text: '',
        questionType: 'radio',
        choices: []
      },
      selectImage: false,
      selectImageForItem: null,
      questionTypes: [
        {
          id: 'radio',
          text: 'Single Choice'
        },
        {
          id: 'checkbox',
          text: 'Multiple Choice'
        }
      ],
      valid: true
    }
  },
  watch: {
    value(val) {
      this.ensureQuestion(val)
    }
  },
  mounted() {
    if (this.value) {
      this.ensureQuestion(this.value)
    }
  },
  methods: {
    ensureQuestion(val) {
      
      val.questionType = val.questionType || 'radio'
      
      if (val.mediaItem) {
        val.mediaItemId = val.mediaItem.mediaId || val.mediaItem.id
      }

      val.choices = val.choices || []
      val.choices.forEach((c) => {
        if (c.mediaItem) {
          c.mediaItemId = c.mediaItem.mediaId || c.mediaItem.id
        }
      })
      this.model = _.cloneDeep(val)
    },
    removeImageFor(item) {
      item.mediaItem = null
      item.mediaItemId = null
    },
    selectImageFor(item){
      this.selectImageForItem = item
      this.selectImage = true
    },
    onImageSelected(image) {
      this.selectImageForItem.mediaItem = image
      this.selectImageForItem.mediaItemId = image.id
      this.selectImage = false
    },
    addChoice() {
      this.model.choices.push({
        label: '',
        value: nanoid(),
        isAnswer: false
      })
    },
    onQuestionTypeChange(){
      this.model.choices.forEach((c) => {
        c.isAnswer = false
      })
    },
    onAnswerChange(choice) {
      if (choice.isAnswer && this.model.questionType === 'radio') {
        this.model.choices.forEach((c) => {
          if (c !== choice) {
            c.isAnswer = false
          }
        })
      }
    },    
    removeChoice(choice) {
      this.model.choices = this.model.choices.filter((c) => c !== choice)
    },
    onSave() {
      this.$emit('save', this.model)
    },
    onPublish() {
      this.$emit('publish', this.model)
    },
    onCancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete', this.model)
    }
  }
}
</script>
