<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Group Name"
                required
                v-model="model.name"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                label="Description"
                auto-grow
                v-model="model.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel"> Cancel </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onSave"
          :disabled="!valid"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {},
  props: {
    item: { type: Object, required: true },
    label: { type: String, default: 'Create' }
  },
  data() {
    return {
      model: {},
      valid: true,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ]
    }
  },
  computed: {
    isNew() {
      return this.model.id ? true : false
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.item)
  },
  watch: {
    item(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      this.$emit('save', this.model)
    }
  }
}
</script>

<style></style>
