<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
    </app-toolbar>

    <version-chips v-model="model"></version-chips>

    <v-alert v-if="model.status == 'published'" type="warning">
      This version is already published
    </v-alert>

    <validation-result
      child-label="Components"
      :validation="validation"
      @click="onItemClicked"
    />

    <v-card flat tile class="mt-2">
      <v-card-actions>
        <v-btn
          v-if="model.id"
          icon
          color="default"
          aria-label="refresh"
          @click="onRefresh"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="model.id"
          plain
          color="default"
          :to="{
            name: 'module-detail',
            params: {
              moduleId: model.id
            }
          }"
        >
          Cancel
        </v-btn>
        <v-btn
          tile
          large
          color="primary"
          @click.once="onPublish"
          :disabled="!canPublish"
        >
          Publish
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import { modules as modulesApi } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page'
import validationResult from '@/components/publishing/validation-result'

export default {
  mixins: [contentPageMixin],
  components: {
    validationResult
  },
  data() {
    return {
      tree: [],
      model: {},
      validation: {},
      isValididated: false
    }
  },
  computed: {
    canPublish() {
      return this.model.status == 'draft' && this.validation.succeeded
    }
  },
  async mounted() {
    this.model = this.module
    this.validate()
  },
  watch: {
    component(val) {
      this.model = _.cloneDeep(val)
    }
  },
  methods: {
    ...mapActions('context', ['clearModule']),
    async validate() {
      this.isValididated = false
      this.validation = await modulesApi.validate(this.module.id)
      this.isValididated = true
    },
    async onPublish() {
      this.loading = true

      const response = await modulesApi.publish(this.module.id)

      this.addMessage({
        message: `Module '${this.model.name}' version ${response.version} has been published.`
      })

      const routeParams = {
        moduleId: this.moduleId
      }

      this.clearModule()

      this.$router.push({ name: 'module-detail', params: routeParams })

      this.loading = false
    },
    async onRefresh() {
      this.loading = true
      await this.validate()
      this.loading = false
    },
    onItemClicked(item) {
      const getComponentIdByTopicId = (id) => {
        const component = this.validation.children.find((c) => {
          const topic = c.children.find((t) => t.id == id)
          return topic
        })

        if (component) {
          return component.id
        }
      }

      let route = 'component-detail'
      const params = {
        moduleId: this.model.id
      }

      switch (item.entityType) {
        case 'component':
          params.componentId = item.id
          break
        case 'topic':
          route = 'topic-detail'
          params.topicId = item.id
          params.componentId = getComponentIdByTopicId(item.id)
          break
      }

      const routeData = this.$router.resolve({ name: route, params })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.card {
  margin: 0px 15px;
}
.procedure {
  margin-left: 15px;
}
</style>
