<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Topics</div>
      <v-spacer></v-spacer>
      <v-btn v-policy:module-update="module" @click="addTopic" color="primary">
        Add
      </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="topics"
        item-key="id"
        @click:row="viewTopic"
        :disable-pagination="true"
        :hide-default-footer="true"
        disable-sort
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    module: { type: Object, required: true },
    topics: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Version', value: 'version', width: '10%', align: 'right' }
        // { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      footerProps: {
        showItemsPerPage: false
      }
    }
  },
  methods: {
    addTopic() {
      this.$emit('addTopic')
    },
    viewTopic(item) {
      this.$emit('viewTopic', item)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
