<template>
  <div class="mt-2">
    <v-row>
      <v-col class="text-h6">Components</v-col>
      <v-spacer />
      <v-col class="text-right">
        <v-btn
          @click="addComponent"
          v-policy:module-update="module"
          color="primary"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="components"
      item-key="id"
      @click:row="viewComponent"
      :disable-pagination="true"
      :hide-default-footer="true"
      class="elevation-1"
      disable-sort
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    module: { type: Object, required: true },
    components: { type: Array, required: true }
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name', width: '75%' },
        { text: 'Status', value: 'status', width: '15%' },
        {
          text: 'Version',
          value: 'version',
          width: '10%',
          sortable: true,
          align: 'right'
        }
        //{ text: '', value: 'actions', sortable: false, width: '10%' }
      ]
    }
  },
  methods: {
    addComponent() {
      this.$emit('addComponent')
    },
    viewComponent(item) {
      this.$emit('viewComponent', item)
    }
  }
}
</script>
