import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/lookups`

const modules = () => {
  return apiClient.get(path, { _ignoreAuth: true }).then(
    (response) => {
      return response.data
    },
    (error) => {
      if (error.response.status == 401) return {}
    }
  )
}

export default {
  modules
}
