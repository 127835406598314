<template>
  <a @click="viewSettings" class="mx-2" id="title">
    <v-toolbar-title>{{ title }}</v-toolbar-title>
  </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isSysAdmin', 'organization']),
    isSystem() {
      return this.$route.path.startsWith('/system')
    },
    title() {
      return this.isSystem
        ? 'System Settings'
        : this.organization?.name ?? 'ADMIN'
    }
  },
  methods: {
    viewSettings() {
      if (this.isSysAdmin && this.$route.name != 'organization-edit') {
        this.$router.push({
          name: 'organization-edit',
          params: { organizationId: this.organization.organizationId }
        })
      } else if (this.$route.path != '/') {
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>

<style scoped>
#title {
  color: #475569;
}
</style>
