<template>
  <div>
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn
        v-if="model.id"
        v-policy:module-update="model"
        plain
        :to="{ name: 'module-edit', params: { moduleId: model.id } }"
      >
        Edit
      </v-btn>
      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon small>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="action in actions"
              :key="action.key"
              @click="selectedAction(action)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="action.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              v-if="model.status === 'draft'"
              v-policy:module-publish="model"
              @click="onPublish"
            >
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                (model.status === 'draft' && !model.isDeleted) ||
                (model.status === 'published' && !model.isDeleted)
              "
              v-policy:module-publish="model"
              @click="showArchive"
            >
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="model"
              @click="showRestore"
            >
              <v-list-item-content>
                <v-list-item-title>Restore</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="model.status === 'archived' && !model.isDeleted"
              v-policy:module-publish="model"
              @click="showDelete"
            >
              <v-list-item-content>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>

    <version-chips v-model="model"></version-chips>

    <v-row class="mt-0">
      <v-col class="text-center">
        <cover-image v-model="model.coverImage" />
      </v-col>
    </v-row>

    <v-row class="m-5">
      <v-col>
        <description
          class="mr-5"
          :description.sync="model.description"
        ></description>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { modules as modulesApi } from '@/services/api'
import contentPageMixin from '@/components/mixins/content-page.vue'

export default {
  mixins: [contentPageMixin],
  components: {},
  data() {
    return {
      unit: 'module',
      model: {},
      actions: [
        // {
        //   key: 'module-access',
        //   title: 'Module Access',
        //   routeName: ''
        // },
        // {
        //   key: 'module-view',
        //   title: 'View',
        //   routeName: ''
        // },
        {
          key: 'versions',
          title: 'Versions',
          callback: () => (this.showVersions = true)
        }
      ],
      showVersions: false
    }
  },
  computed: {
    id() {
      return this.$route.params.moduleId
    },
    archiveEnabled() {
      return this.actions.map((action) => {
        action.disabled =
          this.model.status === 3 && action.key === 'module-archive'
        return action
      })
    },
    components() {
      return this.model.components ? this.model.components : []
    },
    actionRouteParams() {
      return { moduleId: this.id }
    }
  },
  watch: {
    id(newVal) {
      this.loadModule(newVal)
    }
  },
  mounted() {
    if (this.id) {
      this.loadModule(this.id)
    }
  },
  methods: {
    async loadModule(id) {
      this.model = await modulesApi.fetch(id)
    },
    onPublish() {
      this.$router.push({
        name: 'module-publish',
        params: { moduleId: this.model.id }
      })
    },
    async onArchive() {
      await modulesApi.archive(this.id)
      this.model.status = 'archived'
      this.loadModule(this.id)
      this.archiveDialog = false
      this.addMessage({
        message: `Module '${this.model.name}' has been archived.`
      })
    },
    async onRestore() {
      await modulesApi.restore(this.id)
      this.model.status = 'draft'
      this.loadModule(this.id)
      this.restoreDialog = false
      this.addMessage({
        message: `Module '${this.model.name}' has been restored.`
      })
    },
    async onDelete() {
      await modulesApi.delete(this.id)
      this.model.isDeleted = true
      this.deleteDialog = false
      this.$router.push({ name: 'module-index' })
      this.addMessage({
        message: `Module '${this.model.name}' has been deleted.`
      })
    },
    addComponent() {
      this.$router.push({
        name: 'component-create',
        params: { moduleId: this.id }
      })
    },
    viewComponent(item) {
      this.$router.push({
        name: 'component-detail',
        params: {
          componentId: item.id,
          moduleId: this.id
        }
      })
    },
    onViewVersion() {
      this.showVersions = false
      //Todo - link to module version view
    }
  }
}
</script>
