<template>
  <div>
    <page-list
      title="Modules"
      :to="{ name: 'module-create' }"
      to-label="Add"
      @search="onSearch"
      @clear="onSearchClear"
      :headers="headers"
      :items="modules"
      :paging="paging"
      @page:changed="onPageChange"
      @row:view="view"
      :filterCount="filterCount"
    >
      <template v-slot:filters>
        <v-checkbox
          v-model="advancedFilters"
          value="1"
          label="Include archived modules"
          @click="includeArchived = !includeArchived"
        ></v-checkbox>
      </template>

      <template v-slot:item.status="{ item }">
        <span style="text-transform: capitalize">
          {{ item.version + ' / ' + item.status }}
        </span>
      </template>

      <template v-slot:item.publishDateTime="{ item }">
        {{ item.publishDateTime | dateFormat }}
      </template>

      <!-- Disabling actions until functions 
      other than edit necessary:
      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-icon
            small
            class="mr-2"
            @click="view(item)"
            title="view/edit module"
            >mdi-square-edit-outline</v-icon
          >
        </div>
      </template> -->
    </page-list>
  </div>
</template>

<script>
import { modules as modulesApi } from '@/services/api'
import pageList from '@/components/layout/page-list.vue'

export default {
  components: { pageList },
  data() {
    return {
      modules: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Version/Status', value: 'status' },
        { text: 'Published', value: 'publishDateTime' }
        /* Disabling actions until functions 
        other than edit necessary:
        { text: '', value: 'actions', sortable: false } */
      ],
      footerProps: {
        showItemsPerPage: false
      },
      advancedFilters: [],
      includeArchived: false,
      loaded: false,
      paging: {},
      search: ''
    }
  },
  computed: {
    filterCount() {
      return this.advancedFilters.reduce(function (a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    }
  },
  mounted() {
    this.loadModules()
  },
  methods: {
    async loadModules() {
      const response = await modulesApi.get(null, {
        params: {
          term: this.search,
          includeArchived: this.includeArchived,
          page: this.paging.currentPage
        }
      })

      this.paging = response.data.meta.paging
      this.modules = response.data.results
      this.loaded = true
    },
    onPageChange(pagination) {
      if (this.loaded) {
        this.paging.currentPage = pagination.page
        this.loadModules()
      }
    },
    onSearchClear() {
      this.onSearch(null)
    },
    onSearch(term) {
      this.search = term
      this.paging.currentPage = 1
      this.loadModules()
    },
    view(item) {
      this.$router.push({
        name: 'module-detail',
        params: { moduleId: item.id }
      })
    }
  }
}
</script>

<style scoped></style>
