import QuestionCreate from '@/views/questions/create'
import QuestionDetail from '@/views/questions/detail'
import QuestionEdit from '@/views/questions/edit'
import QuestionsIndex from '@/views/questions/index'

export default [
  {
    path: '/questions',
    name: 'questions-index',
    component: QuestionsIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/questions/:entityType/:entityId/new',
    name: 'question-create',
    component: QuestionCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/questions/:questionId',
    name: 'question-detail',
    component: QuestionDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/questions/:id/edit',
    name: 'question-edit',
    component: QuestionEdit,
    meta: {
      authenticated: true
    }
  }
]
