import apiClient from './clients/api-client'
import { API_URL } from '@/config'

const path = `${API_URL}/organizations`
const configOptions = { _ignoreTenant: true }

const client = apiClient.build(path, configOptions)

client.restore = (id) => {
  const restorePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api
    .put(restorePath, null, configOptions)
    .then((response) => response.data)
}

export default client
