import ModuleIndex from '@/views/module/index'
import ModuleCreate from '@/views/module/create'
import ModuleEdit from '@/views/module/edit'
import ModuleDetail from '@/views/module/detail'
import ModulePublish from '@/views/module/publish'

export default [
  {
    path: '/modules',
    name: 'module-index',
    component: ModuleIndex,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/new',
    name: 'module-create',
    component: ModuleCreate,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId',
    name: 'module-detail',
    component: ModuleDetail,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/edit',
    name: 'module-edit',
    component: ModuleEdit,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/modules/:moduleId/publish',
    name: 'module-publish',
    component: ModulePublish,
    meta: {
      authenticated: true
    }
  }
]
